<template>
  <div>
    <b-modal ref="cleaningModal" size="lg" title="Invoice" centered hide-footer content-class="p-0" no-close-on-backdrop
             body-class="p-0">
      <template #modal-title>
        {{ realEstate.fullAddress }}
      </template>
      <div class="p-1">
        <b-form-group v-bind:label="msg('Status')+':'" label-for="input-2">
          <v-select ref="status-select" v-model="request.status"
                    :reduce="(status) => status.value" label="label" :clearable="false"
                    :options="statusOptions" style="min-width: 150px; width: 100%">
            <template #option="option">
              <span :class="`text-${option.variant}`">{{ option.label }}</span>
            </template>
            <template #selected-option="option">
              <span :class="`text-${option.variant}`">{{ option.label }}</span>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group v-bind:label="msg('Note')+':'" label-for="input-2">
          <b-form-textarea v-model="request.notes" rows="3" placeholder="Enter notes"></b-form-textarea>
        </b-form-group>
        <b-form-group v-bind:label="msg('Cleaner')+':'" label-for="input-2">
          <b-form-input v-model="request.cleaner" placeholder="Enter cleaner"></b-form-input>
        </b-form-group>
        <!--Images-->
        <b-form-group v-if="isCleanedStatus && Array.isArray(cleaning.images) ">
          <b-img  v-for="img in cleaning.images" v-bind:key="img" v-bind:src="getUrl(`cleaning/image/${cleaning.id}/${img}`)" fluid
                  style="max-height: 150px;margin-right: 5px"
                  thumbnail></b-img>
        </b-form-group>
        <!--Damage Block-->
        <b-form-group>
          <b-radio-group v-model="hasDamageGroup">
            <b-form-radio button-variant="danger"  value="Y" >
              {{msg('Damaged')}}
            </b-form-radio>
            <b-form-radio value="N" v-bind:disabled="cleaning.cleaned === true">
              {{msg('Not Damaged')}}
            </b-form-radio>
          </b-radio-group>
        </b-form-group>
        <b-form-group v-if="hasDamage">
          <b-form-group v-bind:label="msg('Damage')+':'" label-for="input-2">
            <b-form-textarea v-model="request.damageDescription" rows="3"
                             placeholder="Enter damage description"></b-form-textarea>
          </b-form-group>
        </b-form-group>
        <b-form-group v-if="hasDamage && Array.isArray(damages.images) ">
          <b-img  v-for="img in damages.images" v-bind:key="img" v-bind:src="getUrl(`damage/image/${damages.id}/${img}`)" fluid
                  style="max-height: 150px;margin-right: 5px"
                 thumbnail></b-img>
        </b-form-group>
        <b-button v-if="isEditing" block v-on:click="trySaveCleaningTask" variant="primary">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import vue2Dropzone from "vue2-dropzone";

const cleaningRequest = function () {
  return {
    status: null,
    notes: null,
    cleaner: null,
    hasDamage: false,
    damageDescription: null,
  }
}

export default {
  name: "cleaningModal",
  // eslint-disable-next-line vue/no-unused-components
  components: {vueDropzone: vue2Dropzone,},
  data: () => ({
    request: cleaningRequest(),
    cleaning: {},
    realEstate: {},
    damages: {},
  }),
  computed: {
    statusEnum() {
      return this.$t('cleaningStatusEnum');
    },
    statusOptions() {
      return Object.keys(this.statusEnum).map(key => ({...this.statusEnum[key], value: key}));
    },
    isCleanedStatus() {
      return this.request.status === 'CLEANED';
    },
    hasDamage(){
      return this.cleaning.hasDamage || this.request.hasDamage
    },
    hasDamageGroup:{
      get(){
        return this.request.hasDamage ? "Y":"N";
      },
      set(value){
        this.request.hasDamage = value === "Y";
      }
    },
    dropzoneImagesOptions() {
      let $this = this;
      return function (type) {
        if ($this.cleaning == null || $this.cleaning.id == null) {
          console.warn('dropzoneImagesOptions cleaning not set', $this.cleaning)
          return {}
        }
        return {
          url: window.config.apiUrl + `cleaning/update/${$this.cleaning.id}/upload/img/${type}`,
          headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
          createImageThumbnails: false,
          thumbnailWidth: 50,
          thumbnailHeight: 50,
          addRemoveLinks: true,
          maxFilesize: 5000000,
          acceptedFiles: 'image/*',
        }
      }
    },
    isEditing() {
      return this.cleaning.id != null && this.cleaning.cleaned !== true;
    }
  },
  methods: {
    ...mapActions('cleaning', ['saveCleaningTask', 'fetchById']),
    showById(id){
      let $this = this;
      this.fetchById(id).then(response => {
        let cleaningtask = response.CleaningTask;
        if(response.Damages != null) {
          cleaningtask.damageImages = response.Damages.images;
          cleaningtask.damageDescription = response.Damages.damageDescription;
        }
        console.log("cleaningModal -> showById", response);
        $this.show(response.CleaningTask, response.RealEstate, response.Damages);
      });
    },
    show(cleaning, realEstate, damages) {
      this.images = {task:[], damage:[]};
      this.cleaning = {...cleaning};
      this.realEstate = {...realEstate};
      this.request = {...cleaningRequest(), ...cleaning};
      this.damages = damages || {};
      this.$refs.cleaningModal.show();
    },
    trySaveCleaningTask() {
      let $this = this;
      this.saveCleaningTask({id:this.cleaning.id,request:this.request}).then(response => {
        $this.$emit('updated', response.CleaningTask);
      });
    },
    uploadImageSuccess(file, response) {
      console.log("uploadImageSuccess", {response, file})
      if (response == null || response.objects == null || response.objects['id'] == null)
        return;
      response = response.objects;
      let cleaningtask = response.CleaningTask;
      this.damages = response.Damages || {};
      this.cleaning.hasDamage = cleaningtask.hasDamage;
      this.cleaning.damageId = cleaningtask.damageId;
      if (response.type === 'task') {
        if (this.cleaning.images == null) {
          this.cleaning.images = [];
        }
        this.cleaning.images.push(response['id']);
      }
    },
  }
}
</script>


<style lang="scss">
.cleaning-dropzone {
  padding: 0; min-height: auto;
  .dz-message{
    margin: 0.5rem;
  }
}
</style>
