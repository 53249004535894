<template>
  <div style="min-height: 500px">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <div class="px-1">
        <b-row>
          <b-col>
            <b-form-group :label="msg('Date')" >
              <calendar-picker-range v-model="filter.dateRange" @input="refresh"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="msg('Building')" >
              <building-select-box multiple v-model="filter.buildingIds" @input="refresh"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="msg('Real estate')" >
              <real-estate-picker multiple v-model="filter.realEstatesIds" @input="refresh"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group v-bind:label="msg('Status')+':'" label-for="input-2">
              <v-select ref="status-select" v-model="filter.statuses"
                        :reduce="(status) => status.value" label="label" :clearable="false"
                        :options="statusOptions" style="min-width: 150px; width: 100%">
                <template #option="option">
                  <span :class="`text-${option.variant}`">{{ option.label }}</span>
                </template>
                <template #selected-option="option">
                  <span :class="`text-${option.variant}`">{{ option.label }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <div style="margin-top: 28px">
              <b-checkbox v-model="filter.cleanedOnly" @input="refresh">{{ msg('Only Cleaned') }}</b-checkbox>
              <b-checkbox v-model="filter.damagedOnly" @input="refresh">{{ msg('Damaged') }}</b-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!--Tasks-->
    <b-card>
      <div v-handy-scroll class="table-responsive">
        <b-table ref="plTable" striped hover :items="items" :fields="fields" v-bind:busy="isLoading" @row-clicked="onItemSelected">
          <template #cell(id)="data">
            <span>{{ data.value }}</span>
          </template>
          <template #cell(realEstateId)="data">
            <a class="d-block" :href="`/real_estate/edit/${data.value}`" target="_blank">
              <span class="m-0" v-if="data.value != null && realEstatesMap[data.value] != null">
                {{ realEstatesMap[data.value].fullAddress }}
              </span>
              <span v-else>{{ msg('Unknown') }}</span>
            </a>
          </template>
          <template #cell(contractId)="data">
            <a class="d-block" :href="`/real_estate/contracts/rent/${data.value}`" target="_blank">
              {{ data.item.clientName }} ({{ data.item.requestedCleanTime | moment('DD/MM/YY HH:mm')}})
            </a>
          </template>
          <template #cell(requestedCleanTime)="data">
            <span>{{ data.value | moment('DD/MM/YY HH:mm', true) }}</span>
          </template>
          <template #cell(requestedCleanTimeEnd)="data">
            <span v-if="data.value != null && data.value !== ''">{{ data.value | moment('DD/MM/YY HH:mm', true) }}</span>
          </template>
          <template #cell(cleanedTime)="data">
            <span v-if="data.value != null && data.value !== ''">{{ data.value | moment('DD/MM/YY HH:mm', true) }}</span>
          </template>
          <template #cell(status)="data">
            <b-badge v-bind:variant="statusEnum[data.value].variant">{{ statusEnum[data.value].label }}</b-badge>
          </template>
        </b-table>
      </div>
      <!--Table Paginator-->
      <div class="paginator text-center">
        <!--Paginator-->
        <div class="ml-2" style="display: inline-block ">
          <b-pagination
              :disabled="isLoading"
              limit="11"
              :per-page="pagination.size"
              :current-page="pagination.page"
              :total-rows="pagination.total"
              align="center"
              @input="onPaginationChange"
          />
        </div>
      </div>
    </b-card>

    <cleaning-modal ref="cleaningModal" @updated="refresh"/>
  </div>
</template>
<script>

import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import {mapActions} from "vuex";
import cleaningModal from "@/components/modals/cleaning/cleaningModal.vue";
import buildingSelectBox from "@/components/components/picker/buildingSelectBox.vue";
import CalendarPickerRange from "@/components/widgets/calendar/calendarPickerRange.vue";

const getCleaningFilter = (start, end)=>({
  realEstatesIds: [],
  buildingIds: [],
  cleanedOnly: false,
  damagedOnly: false,
  statuses:[],
  dateRange:{
    start: start,
    end: end
  }
});
export default {
  name: "CleaningTaskView",
  components: {CalendarPickerRange, RealEstatePicker,buildingSelectBox,cleaningModal},
  data: () => ({
    filter: getCleaningFilter(),
    tasks: [],
    realEstatesMap: {},
    isLoading: false,
    pagination: {
      page: 1,
      size: 10,
      total: 0,
      totalPages: 0
    },
  }),
  created() {
    this.resetFilters();
    this.refresh()
    this.$root.$on('crm::branch-updated', this.refresh);
  },
  destroyed() {
    this.$root.$off('crm::branch-updated', this.refresh);
  },
  computed:{
    statusEnum() {
      return this.$t('cleaningStatusEnum');
    },
    statusOptions() {
      return Object.keys(this.statusEnum).map(key => ({...this.statusEnum[key], value: key}));
    },
    items() {
      return this.tasks
    },
    fields() {
      let $this = this
      return [
        {key: 'realEstateId', label: $this.msg('realEstate')},
        {key: 'contractId', label: $this.msg('Contract')},
        {key: 'requestedCleanTime', label: $this.msg('Requested Clean Time')},
        {key: 'requestedCleanTimeEnd', label: $this.msg('Requested Clean Time End')},
        {key: 'cleanedTime', label: $this.msg('Clean Time')},
        {key: 'cleaner', label: $this.msg('cleaner')},
        {key: 'notes', label: $this.msg('notes')},
        {key: 'status', label: $this.msg('Status')},
      ]
    },
  },
  methods: {
    ...mapActions('cleaning', ['fetchAllCleaningTasks']),
    refresh() {
      let $this = this;
      this.fetchAllCleaningTasks({filter: this.filter}).then(data=>{
        console.log("refresh tasks", data);
        this.tasks = data.CleaningTasks || [];
        this.realEstatesMap = (data.RealEstates || []).toObject('id');
      }).catch(()=>{
        $this.$toast.error($this.msg('Error refreshing data'))
      })
    },
    resetFilters() {
      this.filter = getCleaningFilter(this.$moment().startOf('week').format('YYYY-MM-DD'), this.$moment().endOf('day').format('YYYY-MM-DD'));
    },
    onPaginationChange(page) {
      this.pagination.page = page;
      this.refresh();
    },
    onItemSelected(task) {
      this.$refs.cleaningModal.showById(task.id);
    },
  }
}
</script>


<style scoped>

</style>
